.searchContainer {
    @apply w-[58%] flex flex-col items-center justify-center gap-8;
}

.search_bar {
    @apply items-center flex justify-center w-full gap-[16px] h-12 mx-auto;
}

.search_logo {
   @apply h-3/6 m-1 cursor-pointer;
}

.search_box {
    @apply bg-transparent size-full border-none text-[1rem] text-black ml-[5px];
}

.input_wrapper {
    @apply items-center flex h-full gap-2 opacity-90 bg-[white] w-full rounded-lg shadow-[0px_3px_4px_grey];
}

.search_box:focus {
    @apply outline-none;
}

.search_button {
    @apply cursor-pointer h-10 max-w-[100px] pl-2.5 pr-2.5 bg-[#E88A00] text-[aliceblue] border-none text-[1rem] font-bold ml-2.5 rounded-[0.625rem] shadow-[0px_3px_4px_grey];
}

.search_button:hover {
    @apply bg-[#FF9800];
}

.tag_box_container {
    @apply w-full flex justify-center flex-wrap px-4;
}

.tag_box {
    @apply w-full max-w-[846px] flex flex-wrap justify-center items-center gap-2 sm:gap-4;

}

.tag_bar {
    @apply cursor-pointer px-4 py-1 bg-orange-50 rounded-[20px] justify-center items-center gap-2 inline-flex whitespace-nowrap ;
    @apply transition duration-150 ease-out transform hover:bg-orange-200 hover:rounded-[22px] hover:scale-[1.1];
    
}

.tag_text {
    @apply text-center text-neutral-700 text-sm sm:text-base font-normal;
    @apply hover:text-black hover:bg-opacity-20 ;
}


@media (max-width: 800px){
    .searchContainer {
        @apply w-full flex flex-col items-center justify-center gap-8;
    }

    .search_bar {
        @apply items-center flex flex-col justify-center gap-[16px] w-[85%] [grid-gap:16px];
    }

    .search_button {
        @apply cursor-pointer h-10 w-2/5 bg-[#E88A00] text-[aliceblue] border-none text-[1rem] font-bold ml-2.5 rounded-[0.625rem] shadow-[0px_3px_4px_grey];
    }

    .input_wrapper {
        @apply items-center flex h-9 gap-2 opacity-90 bg-[white] w-[100%] rounded-lg shadow-[0px_3px_4px_grey];
    }

    .tag_box_container {
        @apply w-[87%] flex justify-center flex-wrap px-4;
    }

    .tag_text {
        @apply text-center text-neutral-700 text-xs sm:text-base font-normal;
        @apply hover:text-black hover:bg-opacity-20 ;
    }

}