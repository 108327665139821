.footer {
    @apply flex absolute justify-between items-center self-stretch h-[18px] w-[-webkit-fill-available] items-end px-8 py-4 bottom-2.5;
}

.footerCopyRight {
    @apply flex items-center gap-1;
}

.footerLogo {
    @apply w-[18px] h-[18px];
}

.footerCopyRightText {
    @apply not-italic font-normal text-white font-[Roboto] text-[14px] leading-[normal];
}

.frame {
    @apply flex items-center gap-4;
}

.frameText {
    @apply cursor-pointer not-italic font-normal w-max text-white font-[Roboto] text-[14px] leading-[normal]
}

@media (max-width: 800px){
    .footer {
        @apply flex items-center justify-center self-stretch h-[18px] w-[-webkit-fill-available] px-8 py-4 bottom-2.5;
    }
    .frame {
        @apply hidden;
    }
}